import { useState } from 'react';
import Link from 'next/link';
import { appWithTranslation } from 'next-i18next';
import { Hydrate, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ToastContainer, Slide } from 'react-toastify';

import { CookieBanner } from '@/scripts/dataships/cookie-banner/CookieBanner';
import { SegmentAnalytics } from '@/scripts/segment-analytics/SegmentAnalytics';
import { PreventClickJacking } from '@/scripts/prevent-click-jacking/PreventClickJacking';
import { setupQueryClient } from '@core/react-query/query-client';
import { ErrorBoundary } from '@components/error-boundary/ErrorBoundary';
import { setupLogging } from '@core/logging/setup';
import { AnalyticsProvider } from '@components/analytics-provider/AnalyticsProvider';
import { NoPageFlicker } from '@components/no-page-flicker/NoPageFlicker';

import '@core/third-party/scripts';
import '../styles/project-ui.scss';
import '../styles/project-ui-website.scss';

import { setConfig } from '@therapie-ecommerce-ui/ui';
import { AuthenticationProvider } from '@therapie-ecommerce-ui/auth';

// These components need to be imported after the project-ui styles as they overwrite certain styles
import { CartProvider } from '@features/cart-and-checkout/components/cart-provider/CartProvider';
import { getDefaultLayout } from '@/components/layout/default-layout/DefaultLayout';
import { WebViewProvider } from '@features/web-view/components/web-view-provider/WebViewProvider';
import { CancelPendingIntents } from '@features/stripe/components/cancel-pending-intents/CancelPendingIntents';
import { RecaptchaProvider } from '@components/recaptcha/Recaptcha';

import { AppPropsWithLayout } from './_app.types';

setConfig('getLinkComponent', () => Link);
setupLogging();

function MyApp({ Component, pageProps }: AppPropsWithLayout) {
  const [queryClient] = useState(setupQueryClient);

  const getLayout = Component.getLayout ?? getDefaultLayout();

  return (
    <>
      <Scripts />
      <ErrorBoundary>
        <AuthenticationProvider>
          <QueryClientProvider client={queryClient}>
            <CartProvider>
              <WebViewProvider>
                <AnalyticsProvider>
                  <RecaptchaProvider>
                    <Hydrate state={pageProps.dehydratedState}>
                      <NoPageFlicker />
                      {getLayout(<Component {...pageProps} />)}
                    </Hydrate>
                  </RecaptchaProvider>
                </AnalyticsProvider>
              </WebViewProvider>
            </CartProvider>
            <CancelPendingIntents />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </AuthenticationProvider>
      </ErrorBoundary>
      <ToastContainer
        transition={Slide}
        position="top-center"
        hideProgressBar={true}
        closeButton={false}
        newestOnTop={true}
      />
    </>
  );
}

export default appWithTranslation(MyApp);

const Scripts = () => (
  <>
    <CookieBanner />
    <SegmentAnalytics />
    <PreventClickJacking />
  </>
);
