import React from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { cx } from '@therapie-ecommerce-ui/ui';

import { NavigationMobile } from '@/components/layout/default-layout/navigation/mobile/NavigationMobile';
import { Cart } from '@features/cart-and-checkout/components/cart/Cart';
import { THERAPIE_LOGO_SRC } from '@utils/constants/images.constants';
import { Link } from '@components/link/Link';
import { SITE_URLS } from '@utils/constants/site-urls.constants';

import { MyAccountButton } from '../my-account-button/MyAccountButton';

import styles from '../header.module.scss';

export const HeaderMobile = ({ hasBanner = true }: { hasBanner?: boolean }) => {
  const { t } = useTranslation('header');

  return (
    <div className={styles['main-header']}>
      <div className={cx(styles['main-header__body'], styles['main-header__body--mobile'])}>
        <div className={styles['main-header__col']}>
          <NavigationMobile hasBanner={hasBanner} />
        </div>
        <div className={`${styles['main-header__col']} ${styles['main-header__col--expand']}`}>
          <Link
            href={SITE_URLS.HOME}
            className={styles['main-header__link']}
            aria-label={t('logoLinkLabel')}
            prefetch={false}
          >
            <Image src={THERAPIE_LOGO_SRC} alt="Thérapie Logo" width="130" height="32" />
          </Link>
        </div>
        <div className={styles['main-header__col']}>
          <MyAccountButton className={styles['main-header__link']} />
        </div>
        <div className={styles['main-header__col']}>
          <Cart />
        </div>
      </div>
    </div>
  );
};
